<template>
  <sign-page
    title-text="党组织类型"
    :request="request"
    table-size="large"
    :column-list="columnList"
    :form-parms-add="formParmsAdd"
    :tableActionsWidth="100"
    :form-parms-update="formParmsUpdate"
    :table-actions-fixed="true"
    @dataUpdate="dataUpdate"
    :need-data-file="true">
  </sign-page>
</template>

<script>
import {
  orgTypeRequest as request
} from '@/api'

export default {
  computed: {
    formParmsAdd: {
      get () {
        let data = [{
          type: 'input',
          label: '类型名称',
          key: 'name',
          check: {
            required: true
          }
        },
        {
          type: 'input',
          label: '等级',
          key: 'level',
          check: {
            required: true
          }
        },
        {
          type: 'input',
          label: '等级值',
          key: 'sortNum',
          check: {
            required: true,
            rules: {
              type: 'number'
            }
          }
        }]
        return data
      }
    },
    formParmsUpdate: {
      get () {
        let data = []
        this.formParmsAdd.forEach((item) => {
          let newItem = {}
          for (let key in item) {
            newItem[key] = item[key]
          }
          data.push(newItem)
        })
        return data
      }
    },
    columnList: {
      get () {
        let data = [{
          title: '类型名称',
          sort: true,
          field: 'name'
        },
        {
          title: '等级',
          sort: true,
          field: 'level'
        },
        {
          title: '等级值',
          sort: true,
          field: 'sortNum',
          dataType: Number
        }]
        return data
      }
    }
  },
  methods: {
    dataUpdate () {
      this.$store.dispatch('loadOrgTypeList', true)
    },
    async loadData () {
      let datas = await request.get()
      datas.sort((a, b) => a.sortNum - b.sortNum)
      return datas
    }
  },
  data () {
    return {
      request: Object.assign({}, request, {
        get: this.loadData
      })
    }
  }
}
</script>
